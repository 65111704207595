@import url("https://use.typekit.net/bgx5wmw.css");

$primary:       #4C4856;
$secondary:     #E9E3D4;
$success:       #326F51;
$info:          #DED8C5;
$warning:       #E8C11E;
$danger:        #AD1A1F;
$light:         #eeeeee;
$dark:          #111111;

$link-color: $primary;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

$web-font-path: false;
@import "./open_sans";

@import "~bootswatch/dist/spacelab/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/spacelab/bootswatch";
@import "./footer";

.btn-warning {
  color: #333333;
}

body {
  font-family: "tablet-gothic-narrow", sans-serif;
  font-size: 18px;
  color: #333333;
}

smallcaps {
  text-transform: lowercase; font-variant: small-caps;
}

a {
  color: $warning;

  &:hover, &:focus, &:active {
    color: $primary; text-decoration: none;
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

.answers2 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;

  @include media-breakpoint-down(md) {
    position: relative;
  }
}

.jumbotron-title {
  font-family: "abril-text", sans-serif;
  font-weight: 900;
  font-size: 6.25vw;
  line-height: 5vw;
  color: #FFFFFF;
}

.jumbotron-subtitle {
  font-family: "tablet-gothic-narrow", sans-serif;
  font-weight: 400;
  font-size: 1.75vw;
  color: #FFFFFF;
}

.btn-enroll {
  letter-spacing: 1.3px;
}

.testimonial-text {
  font-family: "tablet-gothic-narrow", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.testimonial-author {
  font-family: "tablet-gothic-narrow", sans-serif;
  font-weight: 700;
  font-size: 10px;
}

.testimonial-final {
  font-family: "tablet-gothic-narrow", sans-serif;
  font-size: 33px;
  line-height: 35px;
}

.divider-title {
  font-family: "abril-text", sans-serif;
  font-weight: 900;
  font-size: 32px;
}

.answers-header {
  font-family: "abril-text", sans-serif;
  font-weight: 900;
  font-size: 27px;
}

.answers-header-alt {
  font-family: "abril-text", sans-serif;
  font-weight: 900;
  font-size: 40px;
  line-height: 1.4em;
}

.answers-text {
  font-family: "tablet-gothic-narrow", sans-serif;
  font-weight: 200;
  font-size: 20px;
  line-height: 30px;
}
