.footer {
  background-color: $primary;
  padding: 10px 15px;
  color: #FFFFFF;
}

.footer-bar {
  background-color: $primary; color: #FFFFFF;
}

a.footer-links {
  font-family: "tablet-gothic-narrow", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;

  &:hover, &:focus, &:active {
    color: #FFFFFF; text-decoration: none;
  }
}

.footer-copyright {
  font-family: "tablet-gothic-narrow", sans-serif;
  font-size: 12px;
  color: #FFFFFF;
}

a.footer-links-student {
  font-family: "tablet-gothic-narrow", sans-serif;
  font-weight: 200;
  font-size: 12px;
  color: #FFFFFF;
}
